import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../styles/products.scss"
import iitHyderabadLogo from './../../assets/images/it-hyderabad.png'
import agilityVenturesLogo from './../../assets/images/agilityventures.png'
import derbiLogo from './../../assets/images/derbi.png'


const investorsList = [

  { 
    image: iitHyderabadLogo,
    content: "Recently IIIT Hyderabad was onboarded as one of the key investors.  CIE@IIITH, also known as CIE (Centre of Innovation and Entrepreneurship) is a DST approved incubator operational since 2008. CIE@IIITH has so far supported around 400 startups and has seed- funded 19 startups, 80% of which are cash positive. CIE@IIITH was also instrumental being the kiln of the initiatives that culminated into the foundation of T-Hub. The International Institute of Information Technology, Hyderabad (IIITH) is an autonomous research university founded in 1998 that focuses on the core areas of Information Technology. Some of its research domains include Cognitive Science, Visual Information Technologies, Human Language Technologies, Data Engineering, VLSI and Embedded Systems, Computer Architecture, Wireless Communications, Algorithms and Information Security, Robotics, Building Science, Earthquake Engineering, Computational Natural Sciences and Bioinformatics."
  },
  {
    image: agilityVenturesLogo,
    content: "Machstatz is proud to have Agility Ventures as one of its early-stage investors. Having them onboard during the growth stage helped us build a solid roadmap ahead.  Agility Ventures is a venture fund which invests in seed to pre series A startups. Mr. Dhianu Das cofounder of Agility Ventures has extensive experience as a management consultant, and he brings this dimension into mentoring idiosyncratic dreamers and turns them into successful business magnates. The vision of their venture is to help passionate and hardworking startup founders make their dreams come true. With an exemplary track record in funding, Agility Ventures helped many businesses take off. Few brands in their portfolio as follows: Gomechanic.in, Cleardekho.com, Autobrix.com,  Cleansecar,  Civilcops.com, Skilancers solar iGOLD, NODD, CHUPPS, AMPM 24/7"
  },
  {
    image: derbiLogo,
    content: "DERBI Foundation was onboarded at a time when Machstatz was having couple of customers and building the product. The incubation center helped in office space, labs, mentorship etc.  A Technology Business Incubator supported by Department of Science & Technology, Govt. of India. DERBI envisioned the innovation & startup activities way back in 2009 and started as an e-cell. This e cell grew into an IEDC and then to become a full-fledged TBI with the patronage of NSTEDB, Department of Science & Technology, Government of India. DERBI has been fueling the dreams of startups by enabling all entrepreneurs via PACE, GALLOP and EMERGE successfully, through our pre-incubation, incubation and acceleration programs."
  },

]

class Investors extends Component {

  render() {
    return (
      <Layout>
        <SEO title="Investors" />

        <div className="investors">
          {/* <img src={investorsBanner} className="img-fluid" alt="" srcset=""/> */}
          <div className="inv-banner">
            <div className="container" >
              <h1>
                Digitizing Manufacturing Industries across the globe by joining hands with visionary leaders
                    </h1>
            </div>
          </div>
          <br />
          <br />
          <div className="m-5">
            <h1 className="text-center">Our Investors</h1>
            {investorsList.map((item) => {
              return (
                <div className="container">
                  <div className="row align-items-center py-5">
                    <div className="text-center m-5 col-12 col-md-4 col-lg-3 mx-auto">
                      <img src={item.image} className="w-100" alt="investors-image" />
                    </div>
                    <div className=" col-12 col-md-8 col-lg-9 mx-auto">
                      <p className="text-justify">{item.content}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Investors
